<template>
  <div>
    <b-card>
      <b-card-title>
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-center"
        >
          <div
            class="text-left order-2 mt-1 mt-md-0 order-md-1 w-100 w-md-auto"
          >
            <div class="d-flex w-100 w-md-auto align-items-center">
              <b-form-group class="mb-0 w-md-auto">
                <label class="text-sm-left">Filter</label>
                <b-input-group size="sm">
                  <b-form-input
                    @change="checkEmptySearchInput"
                    id="filterInput"
                    v-model="search"
                    type="search"
                    placeholder="Enter Name / Email"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="primary"
                      @click="searchLeads"
                      :disabled="!search"
                    >
                      Search
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-form-group class="ml-1 mb-0 w-md-auto">
                <label class="text-sm-left">City</label>
                <b-input-group size="sm">
                  <b-form-select
                    :options="cities"
                    @change="cityChanged"
                    v-model="citySelected"
                    id="sortBySelect"
                    class="w-75"
                  >
                    <template v-slot:first>
                      <option value="">
                        -- none --
                      </option>
                    </template>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <b-form-group class="ml-1 mb-0 w-md-auto">
                <label class="text-sm-left">Status</label>
                <b-input-group size="sm">
                  <b-form-select
                    :options="leadStatuses"
                    @change="cityChanged"
                    v-model="leadStatusSelected"
                    id="sortBySelect"
                    class="w-75"
                  >
                    <template v-slot:first>
                      <option value="">
                        -- none --
                      </option>
                    </template>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
          <div class="text-right order-1 order-md-2 w-100 w-md-auto">
            <!-- <b-button @click="initNewLeadAdd" variant="primary">
              <feather-icon icon="PlusIcon"></feather-icon>
              Add New</b-button
            > -->
          </div>
        </div>
      </b-card-title>
      <b-table
        @row-clicked="onRowClicked"
        :busy="isBusy"
        :items="leads"
        hover
        :fields="fields"
        responsive
        show-empty
      >
        <template #empty="scope">
          <div class="text-center">
            <p>0 Leads found!</p>
            <!-- <b-button @click="initNewLeadAdd" variant="primary">
              <feather-icon icon="PlusIcon"></feather-icon>

              Add New
            </b-button> -->
          </div>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner variant="primary" class="align-middle"></b-spinner>
            <strong class="ml-1">Loading Leads...</strong>
          </div>
        </template>

        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(company)="data">
          <div class="d-flex align-items-center">
            <b-avatar rounded size="32" variant="light-company">
              <b-img
                src="https://demos.pixinvent.com/vuexy-vuejs-admin-template-vue2/demo-1/img/toolbox.2bbf519b.svg"
                alt="avatar img"
            /></b-avatar>
            <div>
              <div class="font-weight-bolder">
                {{ data.item.company }}
              </div>
              <div class="font-small-2 text-muted text-capitalize">
                {{ data.item.city }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(name)="data">
          <div>
            <div class="font-weight-bolder">
              {{ data.item.name }}
            </div>
            <div class="font-small-2 text-muted">
              {{ data.item.designation }}
            </div>
          </div>
        </template>

        <template #cell(services)="data">
          <b-badge
            class="mr-1"
            variant="light-primary"
            v-for="service in data.item.services"
            :key="service._id"
          >
            <feather-icon
              v-if="service.dealClosed"
              icon="ThumbsUpIcon"
            ></feather-icon>
            {{ service.name }}</b-badge
          >
        </template>

        <template #cell(consultant)="data">
          <div class="font-weight-bolder">
            {{ data.item.consultant.name }}
          </div>
        </template>
      </b-table>

      <b-card-footer>
        <b-row>
          <b-col cols="3">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                @change="cityChanged"
                id="perPageSelect"
                v-model="limit"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" class="offset-3">
            <b-pagination
              @change="paginate"
              class="justify-content-end"
              v-model="page"
              :per-page="limit"
              :total-rows="totalLeads"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>

    <!-- <CreateLeadsModal
      :isBusy="isModalBusy"
      :lead="lead"
      :show="showCreateLeadsModal"
      :action="action"
      @closeLeadPopUp="closeLeadPopUp"
      @added="addedNew"
      @updated="updatedLead"
    /> -->
  </div>
</template>

<script>
import {
  BTable,
  BCardTitle,
  BCardFooter,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BSpinner,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BImg,
  VBTooltip,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import axios from "axios";
// import CreateLeadsModal from "@/components/leads/CreateLeadsModal.vue";

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BCardTitle,
    BCardFooter,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    // CreateLeadsModal,
    BPagination,
    BSpinner,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BImg,
    BFormSelect,
    BFormSelectOption,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      fields: [
        { key: "index", label: "S.No" },
        { key: "company", label: "Company" },
        "name",
        "email",
        "phone",
        "services",
        "consultant",
      ],
      leads: [],
      totalLeads: 0,
      lead: {
        designation: null,
      },
      cities: [],
      leadStatuses: [
        { text: "Pending Action", value: "pending" },
        { text: "Partially Closed", value: "partially-closed" },
        { text: "Fully Closed", value: "fully-closed" },
      ],
      citySelected: "",
      leadStatusSelected: "",
      showCreateLeadsModal: false,
      limit: 10,
      page: 1,
      search: "",
      isBusy: true,
      isModalBusy: false,
      action: "add-new",
      pageOptions: [5, 10, 25, 50, 100],
    };
  },
  methods: {
    closeLeadPopUp() {
      this.showCreateLeadsModal = false;
      this.resetLeadModal();
    },
    initNewLeadAdd() {
      this.$set(this, "showCreateLeadsModal", true);
    },
    resetPagination() {
      this.$set(this, "page", 1);
      this.$set(this, "search", "");
    },
    resetLeadModal() {
      this.$set(this, "lead", {
        designation: null,
      });
      this.$set(this, "action", "add-new");
    },
    addedNew() {
      this.$set(this, "showCreateLeadsModal", false);
      this.resetPagination();
      this.resetLeadModal();
      this.getLeads();
    },
    updatedLead() {
      this.$set(this, "showCreateLeadsModal", false);
      this.resetLeadModal();
      this.getLeads();
    },
    getLeads() {
      this.isBusy = true;
      let token = this.$getUserToken();
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/leads?limit=${this.limit}&page=${this.page}&search=${this.search}&city=${this.citySelected}&status=${this.leadStatusSelected}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.leads = response.data.leads;
          this.totalLeads = response.data.totalLeads;
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
          console.log(error.response);
        });
    },
    getUniqueCities() {
      let token = this.$getUserToken();
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/leads/unique-cities`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          let cities = response.data.map((city) => {
            return city.charAt(0).toUpperCase() + city.slice(1);
          });
          this.cities = cities;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    onRowClicked(item) {
      this.$router.push(`/leads/${item._id}`);
    },
    checkEmptySearchInput(value) {
      if (!value) {
        this.resetPagination();
        this.getLeads();
      }
    },
    cityChanged() {
      this.$set(this, "page", 1);
      this.getLeads();
    },
    paginate(value) {
      this.$set(this, "page", value);
      this.getLeads();
    },
    searchLeads() {
      this.$set(this, "page", 1);
      this.getLeads();
    },
  },
  mounted() {
    let validStatuses = ["fully-closed", "partially-closed", "pending"];
    if (
      this.$route.query &&
      this.$route.query.status &&
      validStatuses.includes(this.$route.query.status)
    ) {
      this.leadStatusSelected = this.$route.query.status;
    }

    this.getLeads();
    this.getUniqueCities();
  },
};
</script>
